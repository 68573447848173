<div class="logo-head">
  <div class="logo-head-container">
    <div class="menu-btn-container">
      <button mat-icon-button [matMenuTriggerFor]="mainMenu" aria-label="Menu">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <mat-menu #mainMenu="matMenu">
      <button mat-menu-item routerLink="products">{{language === 'th' ? 'สินค้าของเรา' : 'Our Products' }}</button>
      <button mat-menu-item routerLink="about">{{language === 'th' ? 'เกี่ยวกับบริษัท' : 'About Us' }}</button>
      <button mat-menu-item routerLink="contact">{{language === 'th' ? 'ติดต่อเรา' : 'Contact Us' }}</button>
    </mat-menu>

    <div class="logo-container">
      <a routerLink="/">
        <img src="../../assets/rosselle.svg" alt="rosselle-logo">
      </a>
    </div>
    <div class="title">
      <h1 class="mat-h1">Rosselle</h1>
    </div>

    <span class="fill-space"></span>
    <span flex></span>
    <div class="language-switcher-container">
      <button mat-button class="language-switcher" (click)="changeLanguage(language === 'en' ? 'th' : 'en')">
        {{language.toUpperCase()}}
      </button>
      <!-- <div class="language-switcher" (click)="changeLanguage(language === 'en' ? 'th' : 'en')">
        {{ language.toUpperCase() }}
      </div> -->
    </div>

    <div class="linkedin-icon-container">
      <a href="https://www.linkedin.com/company/rosselle" target="_blank">
        <img src="../../assets/linkedin_icon.svg">
      </a>
    </div>

    <div class="email-icon-container">
      <a href="mailto:contact@rosselle.company">
        <mat-icon id="email-icon">email</mat-icon>
      </a>
    </div>
  </div>
</div>
<!-- 
<div class="nav-bar">
  <mat-toolbar id="nav-toolbar">
    <div class="nav-toolbar-but-container">
      <button mat-button routerLink="products" *ngIf="language === 'en'">Products</button>
      <button mat-button routerLink="about" *ngIf="language === 'en'">About</button>
      <button mat-button routerLink="contact" style="background-color: rgba(122, 200, 222, 0.89);"
        *ngIf="language === 'en'">Contact</button>
      <button mat-button routerLink="products" *ngIf="language === 'th'">สินค้า</button>
      <button mat-button routerLink="about" *ngIf="language === 'th'">เกี่ยวกับบริษัท</button>
      <button mat-button routerLink="contact" style="background-color: rgba(122, 200, 222, 0.89);"
        *ngIf="language === 'th'">ติดต่อ</button>
    </div>
  </mat-toolbar>
</div> -->